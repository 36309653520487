<template>
  <div class="wrap">
    <div class="item" v-for="item of list">
      <div v-on:click="toPage(item)">点击参加{{item[0]}}的活动</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
	name: "Cumen",
	created() {
    const list = [
      ["14:30", "0186bf46-c444-f600-7748-a92eedaff08a", "weixin://dl/business/?t=B27jCyMG9yh"],
      ["15:00", "0186bf47-bdf3-08b2-0f0c-027b0a817d80", "weixin://dl/business/?t=mCfN14Ppbyt"],
      ["15:30", "0186bf49-b528-06a3-e0d7-111c12644493", "weixin://dl/business/?t=WPjWVFjz9xn"],
      ["16:00", "0186bf4a-a826-f506-cbd8-a9500ac5c6ed", "weixin://dl/business/?t=vDKCoz0K1uv"],
      ["16:30", "0186bf4b-28da-655d-29b5-f9391827487c", "weixin://dl/business/?t=9STIFLKN7So"],
      ["17:00", "0186bf4b-c5a8-f236-9ca3-751d85163987", "weixin://dl/business/?t=zO24hsGpI3s"],
      ["17:30", "0186bf4c-780f-2546-b0a0-0e6293528a00", "weixin://dl/business/?t=f5Sii0IxJbd"],
      ["18:00", "0186bf50-7f4f-b87b-fd26-5dbbde9522a0", "weixin://dl/business/?t=wBX56RryLPn"],
      ["18:30", "0186bf51-0ecf-8640-c945-a1effd7be170", "weixin://dl/business/?t=Lx9TJ6HWCJa"],
      ["19:00", "0186bf51-9cab-fc02-6cf4-9162ca19329d", "weixin://dl/business/?t=hxdZigZyLUi"],
      ["19:30", "0186bf52-11f1-f531-7c2d-5cb977497a93", "weixin://dl/business/?t=oTyeRR5za7l"],
      ["20:00", "0186bf52-8b5d-bbb1-64c6-9d4520e4cd3e", "weixin://dl/business/?t=ZDendObrJen"],
      ["20:30", "0186bf52-f686-100f-5064-8d30431745ef", "weixin://dl/business/?t=jpntfIdw5Dc"],
      ["21:00", "0186bf53-755b-bca5-d703-96f88443d628", "weixin://dl/business/?t=mx9jnCdO9Zt"],
      ["21:30", "0186bf53-f180-8f65-98a0-0ce76b855b4f", "weixin://dl/business/?t=COx36OwJAye"],
      ["22:00", "0186bf54-9ad7-ca79-9e83-2f4f7e7608b2", "weixin://dl/business/?t=Lg0BmHq8h3i"]
    ]
    this.list = [...list];
    const url = this.getCurrentUrl(list, dayjs());
    window.location.href = url;
    // this.getCurrentUrl(list, dayjs().hour(14).minute(25));
    // this.getCurrentUrl(list, dayjs().hour(14).minute(56));
    // this.getCurrentUrl(list, dayjs().hour(15).minute(25));
    // this.getCurrentUrl(list, dayjs().hour(15).minute(30));
    // this.getCurrentUrl(list, dayjs().hour(23).minute(30));
    // 自动跳转到对应时间的小程序的页面
    // 提前5分钟进入下个时间段的页面
	},
  methods: {
    toPage(data) {
      const [time, id, url] = data;
      window.location.href = url;
    },
    getCurrentUrl(list, currentTime) {
      const times = list.map(l => l[0]).map(t => {
        const [h, m] = t.split(':');
        return dayjs().hour(h).minute(m).second(0);
      });
      let index = 0;
      for (let i = list.length - 1; i >= 0 ; i--) {
        const time = times[i];
        if (time.isBefore(currentTime.add(35, 'minute'))) {
          index = i;
          break;
        }
      }
      console.log(index);
      return list[index][2];
    }
  },
  data() {
    return {
      list: []
    }
  }
};
</script>

<style lang="scss" scoped>
  .wrap {
    padding: 10px;
    font-size: 20px;
    color: #4f5959;
  }
  .item {
    margin-bottom: 10px;
    text-decoration: underline;
  }
</style>
